import React, { useState, useEffect, memo } from 'react'
import { logoHolder } from './Logo.module.scss'
import useMousePosition from '../../hooks/useMousePosition'
import {useWindowSize} from '../../hooks/useWindowSizes'

import { motion } from 'framer-motion'
import { useStaticQuery, graphql } from 'gatsby'

const variants = {
  home: {
    scale:0.8,
    x:'-50%',
    y:'-50%',
    transition: {
      duration:1,
      type:'tween'
    }
  },
  notHome: {
    scale:0.8,
    x:'-50%',
    y:'-50%',
    transition: {
      duration:1,
      type:'tween'
    }
  },
  homeMobile: {
    scale:0.4,
    x:'-50%',
    y:'-50%',
    transition: {
      duration:1,
      type:'tween'
    }
  },
  notHomeMobile: {
    scale:0.4,
    x:'-50%',
    y:'-50%',
    transition: {
      duration:1,
      type:'tween'
    }
  }
}

function pythagorus(sideA, sideB){
  return Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2));
}

const isBetween = (num, a, b) => {
  const min = Math.min.apply(Math, [a, b])
  const max = Math.max.apply(Math, [a, b]);
   return num > min && num < max;
 };

const calculateElev = (mouseOffset) => {
  const lineLength = pythagorus(mouseOffset.x, mouseOffset.y)

  if(isBetween(lineLength, 0, 0.1))
  return '090'

  if(isBetween(lineLength, 0.1, 0.35))
  return '085'

  if(isBetween(lineLength, 0.35, 0.6))
  return '060'

  if(isBetween(lineLength, 0.6, 0.8))
  return '030'

  if(isBetween(lineLength, 0.8, 1.5))
  return '000'

}

const convertAngle = (angle) => {

  if(isBetween(angle, 0, 40))
  return '000'

  if(isBetween(angle, 40, 80))
  return '040'

  if(isBetween(angle, 80, 120))
  return '080'

  if(isBetween(angle, 120, 160))
  return '120'

  if(isBetween(angle, 160, 200))
  return '160'

  if(isBetween(angle, 200, 240))
  return '200'

  if(isBetween(angle, 240, 280))
  return '240'

  if(isBetween(angle, 280, 320))
  return '280'

  
  return '320'

}

const calculateAngle = (mouseOffset) => { 
  let angle = Math.atan2(mouseOffset.y, mouseOffset.x) * 180 / Math.PI ;


  if (angle < 0)
  angle += 360

  return convertAngle(angle)

}




const calculateMouseOffset = (mouse, windowSize) => {
  const centerPoint = {
    x: windowSize.width/2,
    y: windowSize.height/2
  }

  return {
    x: 2 * (mouse.x - centerPoint.x)/windowSize.width,
    y: 2 * (centerPoint.y - mouse.y)/windowSize.height
  }

}



const calculateImgTitle = (mouse, windowSize) => {

  const mouseOffset = calculateMouseOffset(mouse, windowSize)
  
  const elev = calculateElev(mouseOffset)
  const angle = calculateAngle(mouseOffset)

  return `${angle} ${elev}`
}



const rotateLightSource = (mouse, windowSize, setImgTitle) => {
  const mouseOffset = calculateMouseOffset(mouse, windowSize)

  const elevString = calculateElev(mouseOffset)

  let angle = Math.atan2(mouseOffset.y, mouseOffset.x) * 180 / Math.PI

  let counter = 0

  if (angle < 0)
  angle += 360

  let angleString = convertAngle(angle)

  const rotationIntervalId = setInterval(()=>{

    if (angle>360) {
    angle -= 360
    }

    angle += 10

    counter += 10

    if (counter > 360) {
      clearInterval(rotationIntervalId)
      counter = 0
    }

    angleString = convertAngle(angle)

    setImgTitle(`${angleString} ${elevString}`)

  }, 40)

  return rotationIntervalId

}




const imgVariants = {
  hidden: {
    opacity:0,
    transition: {
      type:'tween',
      duration:0.3,
      delay:0.1,
    }
  },
  visible: {
    opacity:1,
    transition: {
      type:'tween',
      duration:0.3,
    }
  }
}

const LogoMemo = memo(({isHome, images, imgTitle, isSmallScreen}) => {
  const animate = isHome ?
                    !isSmallScreen ? 
                      'home' :
                      'homeMobile'
                    :
                    !isSmallScreen ? 
                    'notHome' :
                    'notHomeMobile'

  return (
    
  <motion.div
    className={logoHolder}
    variants={variants}
    initial={false}
    animate={animate}>

  {
   images.map(img => {

     return  <motion.div className={`logoImage`}
                 variants={imgVariants}
                 initial="hidden"
                 key={img.name}
                 animate={`${img.name === imgTitle ? 'visible' : 'hidden' }`}
               >
               {/* <GatsbyImage image={src} alt="Kyla Logo" /> */}
               <img src={`/logoImgs/${img.name}.png`} alt="Kyla La Grange logo" />

             </motion.div>
   })
  }

  </motion.div>
  )
})



export default function Logo({isHome, isSmallScreen}) {
  const mouse = useMousePosition()
  const windowSize = useWindowSize()
  const [imgTitle, setImgTitle] = useState('000')
  const [, setRotateTimerTriggerId] = useState()
  const [, setRotateTimerRotatorId] = useState()

  const { allFile: { nodes: images } } = useStaticQuery(
    graphql`
      query {
        allFile(filter: {relativeDirectory: {eq: "logoImages/individuals"}}) {
          nodes {
            name    
            childImageSharp {
              gatsbyImageData(
                width: 700
                placeholder:BLURRED
                )
            }
          }
        }
      }
    `
  )

  useEffect(()=> {

    setRotateTimerRotatorId(rotateTimerRotatorId => { clearInterval(rotateTimerRotatorId) })
    setImgTitle(calculateImgTitle(mouse, windowSize))
    setRotateTimerTriggerId( rotateTimerId => {
      clearInterval(rotateTimerId)
      return setInterval( () => { 
        setRotateTimerRotatorId(rotateLightSource(mouse, windowSize, setImgTitle))
      } , 5000)
    })

    return (()=>{
      //on unmount  - clear timers
      setRotateTimerRotatorId(rotateTimerRotatorId => { clearInterval(rotateTimerRotatorId) })
      setRotateTimerTriggerId( rotateTimerId => {clearInterval(rotateTimerId) })
    })
  }, [mouse, windowSize, setRotateTimerTriggerId, setRotateTimerRotatorId])



  return (

    <LogoMemo isHome={isHome} images={images} imgTitle={imgTitle} isSmallScreen={isSmallScreen} />

  )
}
