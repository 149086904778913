import React, {useState, useEffect }from "react"

import "../../styles/normalize.css"
import "../../styles/styles.scss"

import {useIsSmallScreen} from '../../hooks/useWindowSizes'

import Header from './Header'
import Footer from "./Footer"
import Clouds from '../components/Clouds'
import MailchimpSignup from '../components/MailchimpSignup'

import {mainHolder} from './Layout.module.scss'
import { AnimatePresence, motion, MotionConfig } from "framer-motion"

const variants={
  pre: {
    scale:0.8,
    opacity:0,
  },
  vis: {
    scale:1,
    opacity:1,
  },
  post: {
    scale:1.2,
    opacity:0
  }
}


const Layout = ({ children, location, ...props}) => {
  const [isHome, setIsHome] = useState(children?.props?.path==='/')
  const isSmallScreen = useIsSmallScreen()


  useEffect(()=> {
    setIsHome(children?.props?.path==='/')
  }, [children, setIsHome])


  return (
    <>
      <Header isHome={isHome} isSmallScreen={isSmallScreen} />
      <MotionConfig transition={{type:'tween', duration: 1}}>
        <AnimatePresence exitBeforeEnter>
          <motion.main key={children?.props?.path} className={mainHolder} variants={variants} initial="pre" animate="vis" exit="post">
            {children}
          </motion.main>
        </AnimatePresence>
      </MotionConfig>
      {/* <Iridescence isHome={isHome} /> */}
      <MailchimpSignup />
      <Clouds isSmallScreen={isSmallScreen} />
      <Footer />
    </>
  )
}

export default Layout
