import React from 'react'
import {footerHolder} from './Layout.module.scss'

export default function Footer() {
  return (
    <footer className={footerHolder}>
      <a href="mailto:mrbjjackson@googlemail.com">Website by Mr BJ Jackson</a>
    </footer>
  )
}
