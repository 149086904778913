import React from 'react'
import {navHolder} from './Layout.module.scss'
import { Link } from 'gatsby'
import { AnimatePresence, motion } from 'framer-motion'

const variants = {
  vis: {
    opacity:1
  },
  hidden: {
    opacity:0
  }
}

export default function Nav({isHome}) {
  return (
    <motion.nav className={navHolder} >
      <ul>
        <li><Link to="/music" title="Music" activeClassName="active">Music</Link></li>
        <li><Link to="/live" title="Live" activeClassName="active">Live</Link></li>
        <li><Link to="/videos" title="Videos" activeClassName="active">Videos</Link></li> 
        <li><a href="https://kylalagrange.tmstor.es/"  title="Kyla La Grange merch store" rel="noreferrer" target="_blank" >Merch</a></li>
        <AnimatePresence>
          { !isHome && <motion.li  key="backLink" variants={variants} initial="hidden" animate="vis"  exit="hidden">
            <Link to="/" title="Home" activeClassName="active">Back</Link>
            </motion.li> }
        </AnimatePresence>
      </ul>
      </motion.nav>
  )
}
