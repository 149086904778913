import React, { useState, useEffect } from "react";
import { mailchimpSignupHolder } from "./MailchimpSignup.module.scss";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import useLocalStorage from "../../hooks/useLocalStorage";

export default function MailchimpSignup() {
   const [email, setEmail] = useState("");
   const [fName, setFName] = useState("");
   const [lName, setLName] = useState("");
   // const [showForm, setShowForm] = useState(false)
   const [result, setResult] = useState();
   const [formDismissed, setFormDismissed] = useLocalStorage(
      "mailchimpFormDismissed",
      false
   );
   const [showForm, setShowForm] = useState(false);
   const [timesUp, setTimesUp] = useState(false);

   const variants = {
      pre: { opacity: 0 },
      vis: { opacity: 1 },
      post: { opacity: 0 },
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      const result = await addToMailchimp(email, {
         FNAME: fName,
         LNAME: lName,
      });
      setResult(result);
   };

   useEffect(() => {
      const timer = setTimeout(() => {
         setTimesUp(true);
         setShowForm(!formDismissed);
      }, 7000);

      return () => {
         clearTimeout(timer);
      };
   }, []);

   useEffect(() => {
      if (timesUp) {
         setFormDismissed(!showForm);
      }
   }, [timesUp, showForm, setFormDismissed]);

   return (
      <MotionConfig transition={{ duration: 0.5, type: "tween" }}>
         <AnimatePresence exitBeforeEnter>
            {showForm && (
               <motion.div
                  className={mailchimpSignupHolder}
                  onClick={() => {
                     setShowForm(false);
                  }}
                  variants={variants}
                  initial="pre"
                  animate="vis"
                  exit="post"
               >
                  <div
                     className="inner"
                     onClick={(e) => {
                        e.stopPropagation();
                     }}
                     role="none"
                  >
                     <h2>Sign up to Kyla's mailing list</h2>

                     <AnimatePresence exitBeforeEnter>
                        {!result ? (
                           <motion.form
                              variants={variants}
                              key="form"
                              onSubmit={handleSubmit}
                              initial="pre"
                              animate="vis"
                              exit="post"
                           >
                              <input
                                 placeholder="Email address"
                                 name="email"
                                 type="email"
                                 onChange={(e) =>
                                    setEmail(e.currentTarget.value)
                                 }
                                 value={email}
                                 required={true}
                              />
                              <input
                                 placeholder="First name"
                                 name="FNAME"
                                 type="text"
                                 onChange={(e) =>
                                    setFName(e.currentTarget.value)
                                 }
                                 value={fName}
                                 required={true}
                              />
                              <input
                                 placeholder="Last name"
                                 name="LNAME"
                                 type="text"
                                 onChange={(e) =>
                                    setLName(e.currentTarget.value)
                                 }
                                 value={lName}
                                 required={true}
                              />
                              <button type="submit">Subscribe</button>
                           </motion.form>
                        ) : (
                           <motion.div
                              variants={variants}
                              key="response"
                              className="response"
                              initial="pre"
                              animate="vis"
                              exit="post"
                           >
                              <div
                                 className="msg"
                                 dangerouslySetInnerHTML={{
                                    __html: result.msg,
                                 }}
                              />
                              {result.result === "success" ? (
                                 <button
                                    type="button"
                                    onClick={() => {
                                       setShowForm(false);
                                    }}
                                 >
                                    Close
                                 </button>
                              ) : (
                                 <button
                                    type="button"
                                    onClick={() => {
                                       setResult(null);
                                    }}
                                 >
                                    Try again
                                 </button>
                              )}
                           </motion.div>
                        )}
                     </AnimatePresence>
                     <button
                        type="button"
                        className="closeForm"
                        onClick={() => {
                           setShowForm(false);
                        }}
                     >
                        &times;
                     </button>
                  </div>
               </motion.div>
            )}
         </AnimatePresence>
      </MotionConfig>
   );
}
