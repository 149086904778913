import React from 'react'
import {headerHolder} from './Layout.module.scss'
import Logo from '../components/Logo'
import KylaHandsUp from '../components/KylaHandsUp'
import Nav from './Nav'
import SocialNav from './SocialNav'

export default function Header({isHome, isSmallScreen}) {
  return (
    <header className={ headerHolder }>
      <h1>Kyla La Grange</h1>
      <Logo isHome={isHome} isSmallScreen={isSmallScreen} />
      <Nav isHome={isHome} />
      <SocialNav isHome={isHome} />
      <KylaHandsUp isHome={isHome} isSmallScreen={isSmallScreen} />
    </header>
  )
}
