const React = require("react");
const Layout = require("./src/components/layout/Layout").default;
// const songkick = require("./static/js/songkickWidget");

exports.wrapPageElement = ({ element, props }) => {
   return <Layout {...props}>{element}</Layout>;
};

exports.shouldUpdateScroll = () => false;

// const insertJS = () => {
//    const addJS = (src) => {
//       const s = document.createElement(`script`);
//       s.type = `text/javascript`;
//       s.src = src;
//       document.getElementsByTagName(`head`)[0].appendChild(s);
//    };

//    addJS(songkick);
// };

// exports.onInitialClientRender = () => {
//    insertJS();
// };
