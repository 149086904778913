import { useEffect, useState } from 'react'

export default function useMousePosition() {
  const [mouse, setMouse] = useState({x: 0, y: 0});

  const onMouse = ({clientX, clientY}) => {
    setMouse({x: clientX, y: clientY});
  };

  useEffect(() => {

    if (typeof window !== undefined) 
    window.addEventListener('mousemove', onMouse);

    return () => {

      if (typeof window !== undefined) 
      window.removeEventListener('mousemove', onMouse);

    };
  }, []);

  return mouse;
}