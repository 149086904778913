import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { kylaHandsUpHolder } from './kylaHandsUpHolder.module.scss'
import { motion } from 'framer-motion'

export default function KylaHandsUp({isHome, isSmallScreen}) {

  const variants = {
    home: {
      y: '8vh',
      scale: 0.85,
      filter: 'blur(0px)',
      x: '0vw',
      transition: {
        type:'tween',
        duration:1.5
      }
    },
    notHome: {
      y: '20vh',
      scale:1.5,
      x: '17vw',
      filter: 'blur(10px)',
      transition: {
        type:'tween',
        duration:1.5
      }
    },
    homeMobile: {
      y: '-6vh',
      scale: 1.15,
      filter: 'blur(0px)',
      x: '0vw',
      transition: {
        type:'tween',
        duration:1.5
      }
    },
    notHomeMobile: {
      y: '-10vh',
      scale:1.8,
      x: '17vw',
      filter: 'blur(10px)',
      transition: {
        type:'tween',
        duration:1.5
      }
    }
  }

  const animate = isHome ?
  !isSmallScreen ? 
    'home' :
    'homeMobile'
  :
  !isSmallScreen ? 
  'notHome' :
  'notHomeMobile'


  return (
    <div className={kylaHandsUpHolder}>
      <motion.div className="inner" variants={variants} initial={animate} animate={animate}>
        <StaticImage
          src="../../images/Kyla-hands-up.png" 
          width={700}
          quality={90}
          alt="Kyla La Grange - Hands Up"
          objectFit="contain"
          backgroundColor="transparent"
          placeholder="blurred"
          objectPosition="center bottom"
        />
      </motion.div>
    </div>
  )
}
