import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { cloudsHolder } from "./Clouds.module.scss";

export default function Clouds() {
   return (
      <div className={cloudsHolder}>
         <video width="1270" height="720" autoPlay={true} muted loop>
            <source
               src="https://wp.stagnesmmi.co.uk/wp-content/uploads/other-sites/kylalagrange/video/clouds-1200kbps.mp4"
               type="video/mp4"
            />
            Your browser does not support the video tag.
         </video>
         <StaticImage
            src="../../images/clouds.jpg"
            width={1200}
            quality={80}
            alt="Clouds BG"
            objectFit="cover"
            backgroundColor="transparent"
            placeholder="blurred"
         />
      </div>
   );
}
