import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faSpotify, faApple, faYoutube , faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faEnvelope} from '@fortawesome/free-regular-svg-icons'

import { socialNavHolder } from './Layout.module.scss'
import { Link } from 'gatsby'

export default function SocialNav() {
  return (
    <nav className={socialNavHolder}>
      <ul>
        <li><a href="https://www.instagram.com/kylalagrange" title="Instagram" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} /></a></li>
        <li><a href="https://www.facebook.com/kylalagrange" title="Facebook" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FontAwesomeIcon icon={faFacebook} /></a></li>
        <li><a href="https://www.twitter.com/kylalagrange" title="Twitter" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><FontAwesomeIcon icon={faTwitter} /></a></li>
        <li><a href="https://open.spotify.com/artist/6KcmUwBzfwLaYxdfIboqcp" title="Spotify" target="_blank" rel="noopener noreferrer" aria-label="Spotify"><FontAwesomeIcon icon={faSpotify} /></a></li>
        <li><a href="https://music.apple.com/gb/artist/kyla-la-grange/419417742" title="Apple Music" target="_blank" rel="noopener noreferrer" aria-label="Apple"><FontAwesomeIcon icon={faApple} /></a></li>
        <li><a href="https://www.youtube.com/channel/UCADB1vK7fghvgDwMrMs0ypw" title="Youtube" target="_blank" rel="noopener noreferrer" aria-label="Youtube"><FontAwesomeIcon icon={faYoutube} /></a></li>
        <li><Link to="/contact" title="Contact Kyla La Grange"><FontAwesomeIcon icon={faEnvelope} /></Link></li>
      </ul>
    </nav>
  )
}
